import * as React from 'react';

import { api } from '../../environment';
import Table from '../../components/table/Table';
import CheckBox from '../../components/formElements/CheckBox';
import { RemoveRedEye } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import {utils, write}from 'xlsx';
import { saveAs } from 'file-saver';
import { FormDataBuilder, makeFormControls } from '../../components/formElements/util';
import Form from '../../components/formElements/Form';

export default function AbstractsView(){
    const [dataRows, setDataRows] = React.useState([]);
    

    const orderData = (data)=>{
        console.log("ORDER", data)
        return data.map((row, index) =>{
            row['order'] = index+1;
            return  row;
        })
    }

    const defineActions = (data)=>{
        return data.map((row, index)=>{
             row['action'] = <Link to={"/crs/pregled-sazetaka/"+row['id']}><RemoveRedEye/></Link>
             return row;
        })
    };


    const getData = (body) => {
        let call = fetch(api+"/get_abstracts",{
            method: "POST",
            headers: {
                "Content-Type": "application/json",
              },
              
              body:JSON.stringify({token:localStorage.getItem('site'), criteria:filter, ...body})
            })
            try{
        return call.then(response=>response.json())
            } catch (err){
                console.log(err)
                return [];
            }
    }

    const header = [{name:"order", label:"#", width:"5%"},{name:"activity_title", label:"Naslov", width:"25%", overflowX:'scroll'}, {name:"type", label:"Oblik sudjelovanja", width:"25%"}, {name:'theme', label:'Tematsko područje', width:"20%"}, {name:"status", label:"Status prijave", width:"20%"}, {name:"action", label:"akcija", width:"5%"}, ]

    const mapType = (value)=>{
        const values = {
            "1": "Usmeno izlaganje",
            "2": "Poster izlaganje",
            "3": "Simpozij",
            "4": "Okrugli stol",
            "5": "Radionica",
            "6": "Stručni domjenak",
            "7": "Sastanak",
            "8": "Predstavljanje",
            "9": "Izvan okvira",
            "10": "Kreativci",
            "11": "Ostalo"
        }
        return values[value];
    }

    const mapTheme = (value) =>{
        const values = {
            "1": "Zaštita mentalnog zdravlja",
            "2": "Digitalna transformacija",
            "3": "Odgoj i obrazovanje",
            "4": "Zaštitni čimbenici",
            "5": "Izazovi za psihologe",
            "6": "Interdisciplinarna suradnja"
        }
        return values[value]
    }

    const mapStatus = (value) =>{
        const values = {
            "0": "Čeka pregled",
            "1": "Poslano na recenziju",
            "2": "Prihvaćeno",
            "3": "Prihvaćeno uz izmjene",
            "4": "Odbijeno"
        }
        return values[value];
    }

    const valueMapper = (name, value) => {
        let mapping = {
            "type" : {
                1: "Usmeno izlaganje",
                2: "Poster izlaganje",
                3: "Simpozij",
                4: "Okrugli stol",
                5: "Radionica",
                6: "Stručni domjenak",
                7: "Sastanak",
                8: "Predstavljanje",
                9: "Izvan okvira",
                10: "Kreativci",
                11: "Ostalo"
            },
            "theme":{
                1: "Zaštita mentalnog zdravlja",
                2: "Digitalna transformacija",
                3: "Odgoj i obrazovanje",
                4: "Zaštitni čimbenici",
                5: "Izazovi za psihologe",
                6: "Interdisciplinarna suradnja"
            },
            "status":{
                0: "Čeka pregled",
                1: "Poslano na recenziju",
                2: "Prihvaćeno",
                3: "Prihvaćeno uz izmjene",
                4: "Odbijeno"
            }

        }
        if(mapping[name]==undefined) return value;
        console.log("VALUE",mapping[name][value])
        return mapping[name][value]
    }

    const mapValues = (data) =>{
        return data.map((row, index)=>{
            for(let name in row){
                row[name] = valueMapper(name,row[name])
            }
            return row
        })
    }

    // const mapValues = (data) =>{
    //     for(let row of data){
    //         row['type'] = mapType(row['type']);
    //         row['theme'] = mapTheme(row['theme']);
    //         row['status'] = mapStatus(row['status']);
    //     }
    //     return data
    // }

    const filterFormData = (new FormDataBuilder())
    .addTextField('activity_title', 'Pretraži naslove', false, false, [], {flex:"1 1 20%"}, null, 'input-field-no-border input-bottom-border')
    .addSelectField('type', 'Tip izlaganja', false, [
        {value:"0", text:'Sve'},
        {value:"1", text:"Usmeno izlaganje"},
    {value:"2", text:"Poster izlaganje"},
    {value:"3", text:"Simpozij"},
    {value:"4", text:"Okrugli stol"},
    {value:"5", text:"Radionica"},
    {value:"6", text:"Stručni domjenak"},
    {value:"7", text:"Sastanak stručne sekcije/radne skupine"},
    {value:"8", text:"Predstavljanje knjiga, psihologijskih mjernih instrumenata te drugih publikacija i uradaka"},
    {value:"9", text:'„Izvan okvira"'},
    {value:"10", text:"Psiholozi kao kreativci"},
    {value:"11", text:"Ostale aktivnosti"}
        
    ], false,[], {flex:'1 1 20%'}, null, 'input-field-no-border input-bottom-border')
    .addSelectField('theme', 'Tematsko područje', false, [
        {value:"0", text:'Sve'},
        {value:"1", text:"Uloga i doprinos psihologije u zaštiti mentalnog zdravlja"},
        {value:"2", text:"Mentalno zdravlje i radna produktivnost u doba digitalne transformacije"},
        {value:"3", text:"Uloga i doprinos psihologa u sustavu odgoja i obrazovanja"},
        {value:"4", text:"Zaštitni i rizični čimbenici u prilagodbi na bolest"},
        {value:"5", text:"Izazovi za psihologe"},
        {value:"6", text:"Uloga i doprinos psihologije u interdisciplinarnoj suradnji"}
    ], false,[], {flex:'1 1 20%'}, null, 'input-field-no-border input-bottom-border')
    .addSelectField('status', 'Status', false, [
        {value:"-1", text:'Sve'},
        {value:'0', text:'Čeka pregled'},
        {value:'1', text:'Na recenziji'},
        {value:'2', text:'Prihvaćena'},
        {value:'3', text:'Prihvaćena uz izmjene'},
        {value:'4', text:'Odbijena'}
    ], false,[], {flex:'1 1 20%'}, null, 'input-field-no-border input-bottom-border').get();
    
    const [filter, setFilter] = React.useState(makeFormControls(filterFormData));

    React.useEffect(()=>{
        getData().then(
            data=>setDataRows(defineActions(orderData(mapValues(data))))
        )
    }, [filter])

    

    const exportToExcel = (data, fileName) => {
        const worksheet = utils.json_to_sheet(data);
        const workbook = utils.book_new();
        utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        const excelBuffer = write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], {type: 'application/octet-stream'});
        saveAs(blob, `${fileName}.xlsx`);
      };

      const mutateDataForExport = (data) =>{
        const mapping = {

        }

        

        return data.map((row, index)=>{
            let mapping = {
                "type": "Vrsta izlaganja",
                "activity_title": "Naslov",
                "presenter": "Voditelj izlaganja",
                "presenter_email": "E-Mail adresa voditelja izlaganja",
                "contact": "Osoba za kontakt",
                "contact_email": "E-Mail adresa osobe za kontakt",
                "theme": "Tematsko područje",
                "note": "Napomena",
                "status":"Status prijave"
            }
            let mutatedRow = {}
            for(let name in mapping){
                mutatedRow[mapping[name]] = row[name]
            }
        mutatedRow["Status prijave"] = row["status"]
            console.log(row);
            return mutatedRow
        })


    }


    return (
        <>
        <h1 style={{marginBottom:"2rem"}}>Pregled prijava sažetaka</h1>
        <div className="input-field" style={{marginTop:"0.5rem"}}>
            <div className="flex-row" style={{gap:"1%"}}>
        <Form formData={filterFormData} formControls={filter} setFormControls={setFilter}/>
        </div>
        <Table header={header} dataSource={dataRows!=undefined?dataRows:[]} layout="fixed"/>
        <button className="modal-button" style={{margin:"1rem"}}  onClick={()=>{exportToExcel(mutateDataForExport(dataRows), 'aktivnosti')}}>Izvoz u Excel</button>
        </div>
        </>
    )
}